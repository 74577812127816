@import "../colors";
@import "../sizes";
@import "../fonts";
@import "../rouge-themes/gruvbox";

pre * {
  font-family: $font-monospace;
  letter-spacing: normal;
}

p code {
  display: inline-block;
  font-family: $font-monospace;
  background: $worstpractice-tanned;
  padding: 0 0.5rem;
  hyphens: none;
}

pre code {
  display: block;
  width: 100%;
  margin: 0;
  hyphens: none;
}

.c-content pre.highlight {
  width: 115%;
  margin-left: -7.5%;
  margin-top: 4rem;
  font-size: $size-sourcecode;
  line-height: $size-sourcecode-line;
  border: 0.2rem dashed $dark-grey;
  border-radius: 1rem;
  padding: 1rem;
  white-space: normal;

  & > code {
    max-height: 75vh;
    overflow: auto;
    width: 100%;
  }

  & > code::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  & > code::-webkit-scrollbar-thumb {
    background-color: $dark-grey-50;
    border-radius: 0.4rem;
  }

  & > code::-webkit-scrollbar-thumb:hover {
    background-color: $dark-grey;
  }

  & > code::-webkit-scrollbar-button {
    display: none;
  }

  & > code::-webkit-scrollbar,
  & > code::-webkit-scrollbar-track,
  & > code::-webkit-scrollbar-track-piece,
  & > code::-webkit-scrollbar-corner {
    background-color: $transparent;
  }

  .rouge-table {
    display: block;
    width: max-content;

    tbody {
      display: block;
      margin: 0;
      padding: 0;
      width: max-content;
    }

    tr {
      display: flex;
      position: relative;
      margin: 0;
      padding: 0;
      width: max-content;
    }
  }

  .rouge-gutter {
    display: block;
    width: 3.5rem;
    text-align: right;
    white-space: nowrap;
    padding-right: 1rem;
    border-right: 0.2rem solid $code-gutter-border;
    position: sticky;
    z-index: 2;
    left: 0;
    background-color: $code-gutter-background;

    .lineno {
      width: 2.5rem;
      white-space: pre;
    }
  }

  .rouge-code {
    display: block;
    padding: 0 1rem;
    z-index: 1;

    pre {
      white-space: pre;
    }
  }
}

.highlighter-rouge pre.highlight::before {
  content: '';
  display: block;
  position: absolute;
  top: -2.6rem;
  left: 4.8rem;
  color: $dark-grey;
  font-weight: 300;
  font-family: $font-serif;
  font-style: italic;
  font-size: $size-sourcecode;
  line-height: $size-sourcecode-line;
}

.c-content {
  .highlighter-rouge pre.highlight::before {
    content: '';
    display: block;
    color: $dark-grey;
    font-weight: 300;
    font-family: $font-serif;
    font-style: italic;
    font-size: $size-sourcecode;
    line-height: $size-sourcecode-line;
  }

  .language-php pre.highlight::before {
    content: 'Source code language: PHP'
  }

  .language-json pre.highlight::before {
    content: 'Source code language: JSON'
  }

  .language-js pre.highlight::before {
    content: 'Source code language: JavaScript'
  }

  .language-css pre.highlight::before {
    content: 'Source code language: CSS'
  }

  .language-sass pre.highlight::before {
    content: 'Source code language: SASS'
  }

  .language-html pre.highlight::before {
    content: 'Source code language: HTML'
  }

  .language-typescript pre.highlight::before {
    content: 'Source code language: Typescript'
  }

  .language-jsx pre.highlight::before {
    content: 'Source code language: JSX'
  }

  .language-tsx pre.highlight::before {
    content: 'Source code language: TSX'
  }

  .language-diff pre.highlight::before {
    content: 'Diff comparision'
  }

  .language-bash pre.highlight::before {
    content: 'Bash/ZSH'
  }

  .language-docker pre.highlight::before {
    content: 'Dockerfile'
  }

  .language-yaml pre.highlight::before {
    content: 'YAML file'
  }

  .language-markdown pre.highlight::before {
    content: 'Markdown file'
  }

  .language-liquid pre.highlight::before {
    content: 'Liquid template'
  }
}
