@import "../colors";
@import "../sizes";
@import "../fonts";

.g-gdpr {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  width: 100%;
  height: 100%;
  background: $white-75;
  z-index: 1000;

  .g-tab {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70rem;
    height: 50rem;
    background: $white;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 1rem 0 $black-75;
    border-radius: 0.5rem;

    &.-active {
      display: block;
    }
  }

  .g-header {
    height: 12.5rem;
    padding: 3rem 2rem;
  }

  .g-header__title {
    font-size: $size-header-large;
    line-height: $size-header-large-line;
    text-align: center;
    margin: 0;
  }

  .g-body {
    padding: 0 2rem;
    height: 25rem;
    overflow: auto;

    a {
      color: $worstpractice-red;
      text-decoration: none;
      outline: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .g-buttons {
    display: flex;
    height: 12.5rem;
    padding: 3rem 2rem;
    justify-content: space-evenly;
    outline: none;

    button {
      width: 20rem;
      padding: 0;
      margin: 0;
      background: $dark-grey;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: $size-button-normal;
      line-height: $size-button-normal-line;
      outline: none;
      color: $white;
    }

    button:hover {
      background: $worstpractice-green;
      cursor: pointer;
    }
  }
}
