$code-background: #282828 !global;
$code-gutter-background: #282828 !global;

pre.highlight {
  color: #fbf1c7;
  background-color: #282828;

 .w {
    color: #fbf1c7;
  }
 .err {
    color: #fb4934;
    font-weight: bold;
  }
 .c, .cd, .cm, .c1, .cs {
    color: #928374;
    font-style: italic;
  }
 .cp {
    color: #8ec07c;
  }
 .nt {
    color: #fb4934;
  }
 .o, .ow {
    color: #fbf1c7;
  }
 .p, .pi {
    color: #fbf1c7;
  }
 .gi {
    color: #b8bb26;
  }
 .gd {
    color: #fb4934;
  }
 .gh {
    color: #b8bb26;
    font-weight: bold;
  }
 .k, .kn, .kp, .kr, .kv {
    color: #fb4934;
  }
 .kc {
    color: #d3869b;
  }
 .kt {
    color: #fabd2f;
  }
 .kd {
    color: #fe8019;
  }
 .s, .sb, .sc, .sd, .s2, .sh, .sx, .s1 {
    color: #b8bb26;
    font-style: italic;
  }
 .si {
    color: #b8bb26;
    font-style: italic;
  }
 .sr {
    color: #b8bb26;
    font-style: italic;
  }
 .se {
    color: #fe8019;
  }
 .nn {
    color: #8ec07c;
  }
 .nc {
    color: #8ec07c;
  }
 .no {
    color: #d3869b;
  }
 .na {
    color: #b8bb26;
  }
 .m, .mf, .mh, .mi, .il, .mo, .mb, .mx {
    color: #d3869b;
  }
 .ss {
    color: #83a598;
  }
}
