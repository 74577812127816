@import "../colors";
@import "../sizes";
@import "../fonts";

.c-content {
  border-radius: 0;
}

.g-gdpr .g-tab {
  top: initial;
  left: 0;
  width: 100%;
  transform: none;
  border-radius: 0;
  bottom: 0;
}

.codeOfTheDay {
  display: none;
}
